import React  from 'react';
import SplitContent from "../../../components/content/SplitContent";
import { Helmet } from "react-helmet";
import Signup from "../../../components/content/Signup";
import Questions from "../../../components/content/Questions";

const CourseName = "Stop de bloeding";
const CoursePageTitle = `Cursus: ${CourseName} | ACR-Opleidingen.nl`;
const CourseMetaDescription = `ACR-opleidingen.nl: ${CourseName}`;

const CourseStopDeBloeding = () => (
    <SplitContent>
        <Helmet>
            <title>{CoursePageTitle}</title>
            <meta name="description" content={CourseMetaDescription} />
        </Helmet>
        <h4>{CourseName}</h4>
        <div className="twelve wide column"><p>Hoe te handelen bij rampen en aanslagen?</p>
            <p>In 1 middag of avond behandelen wij hoe u moet handelen als hulpverlener bij rampen en aanslagen om
                slachtoffers te kunnen helpen.</p><p>Aan het eind van deze training ontvangt u een certificaat : “stop
                de bloeding ,red een leven!”</p><p>Wij leren u hoe u een tourniquet moet aanleggen, hoe u een wond moet
                opstoppen met hemostatisch gaas en hoe u een traumazwachtel dient aan te leggen.</p>
            <Signup />
            <Questions />
        </div>
    </SplitContent>
);
export default CourseStopDeBloeding;
