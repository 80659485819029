import React, { Component }  from 'react';
import { Grid } from "semantic-ui-react";
import RightCol from "../../components/content/right-column/RightCol";

export default class SplitContent extends Component {
    render() {
        return (
            <Grid stackable>
                <Grid.Column width={12}>
                    {this.props.children}
                </Grid.Column>
                <Grid.Column width={4} className="col-right">
                    <RightCol />
                </Grid.Column>
            </Grid>
        );
    }
}
