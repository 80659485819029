import React  from 'react';
import SplitContent from "../../../components/content/SplitContent";
import { Helmet } from "react-helmet";

const ContentTitle = "Algemene voorwaarden";
const PageTitle = 'Algemene voorwaarden | ACR-Opleidingen.nl';
const MetaDescription = 'ACR-opleidingen.nl: Algemene voorwaarden';

const AlgemeneVoorwaarden = () => (
    <SplitContent>
        <Helmet>
            <title>{PageTitle}</title>
            <meta name="description" content={MetaDescription} />
        </Helmet>
        <h4>{ContentTitle}</h4>
        <p>
            <span>Wij zijn onze algemeenvoorwaarden nog aan het opstellen en zal binnenkort hier worden geplaatst.</span>
        </p>
    </SplitContent>
);
export default AlgemeneVoorwaarden;
