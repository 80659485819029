import React, { Component } from 'react';
import {Icon, Menu} from "semantic-ui-react";
import { Link } from "react-router-dom";

export default class GeneralMenu extends Component {
    render() {
        return (
            <Menu vertical>

                <Menu.Item as={Link} to="/cursus/basis-cursus-ehbo"><Icon name='right triangle' /> Basis cursus EHBO</Menu.Item>
                <Menu.Item as={Link} to="/cursus/basis-cursus-bhv"><Icon name='right triangle' /> BHV basis</Menu.Item>
                <Menu.Item as={Link} to="/cursus/ehbo-aan-kinderen"><Icon name='right triangle' /> EHBO aan kinderen</Menu.Item>
                <Menu.Item as={Link} to="/cursus/eerste-hulp-bij-drank-en-drugs"><Icon name='right triangle' /> EHBDD</Menu.Item>
                <Menu.Item as={Link} to="/cursus/basic-life-support"><Icon name='right triangle' /> BLS Basic life support </Menu.Item>
                {/*<Menu.Item as={Link} to="/cursus/pediatric-basic-life-support"><Icon name='right triangle' /> PBLS Pediatric (BLS) </Menu.Item>*/}
                {/*<Menu.Item as={Link} to="/cursus/wandelletsel"><Icon name='right triangle' /> Wandelletsel</Menu.Item>*/}
                <Menu.Item as={Link} to="/cursus/stop-de-bloeding"><Icon name='right triangle' /> Stop de bloeding</Menu.Item>

                <Menu.Item as={Link} to="/contact"><Icon name='right triangle' /> Contact</Menu.Item>

            </Menu>
        );
    }
}
