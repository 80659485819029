import React  from 'react';
import SplitContent from "../../../components/content/SplitContent";
import { Helmet } from "react-helmet";
import {Link} from "react-router-dom";
import {Segment} from "semantic-ui-react";
import CourseImage from "../../../components/content/main-content/CourseImage";
import ImageEhbo from "../../../images/courses/ehbo.jpg";
import ImageBHV from "../../../images/courses/bhv.jpg";
import ImageEhboKinderen from "../../../images/courses/ehbo-kinderen.jpg";
import ImageEhbdd from "../../../images/courses/ehbdd.jpg";
import ImageBsl from "../../../images/courses/bls.jpg";
import ImageStopBleeding from "../../../images/courses/stop-bleeding.jpg";

const ContentTitle = "Alle cursussen";
const CoursePageTitle = `${ContentTitle} | ACR-Opleidingen.nl`;
const CourseMetaDescription = `ACR-opleidingen.nl: ${ContentTitle}`;

const AllCourses = () => (
    <SplitContent>
        <Helmet>
            <title>{CoursePageTitle}</title>
            <meta name="description" content={CourseMetaDescription} />
        </Helmet>
        <h4>{ContentTitle}</h4>
        <div className="twelve wide column">
            <div className="content-products">
                <div className="course-blocks">
                    <Link to='/cursus/basis-cursus-ehbo'>
                        <Segment>
                            <CourseImage src={ImageEhbo}/>
                            <h3 className="content-title">EHBO basis cursus</h3>
                            <span>De basis cursus volgens Het Oranje Kruis</span>
                            {/*<span className="product-badge"><b>50%</b> partner voordeel</span>*/}
                        </Segment>
                    </Link>
                    <Link to='/cursus/basis-cursus-bhv'>
                        <Segment>
                            <CourseImage src={ImageBHV}/>
                            <h3 className="content-title">BHV basis cursus</h3>
                            <span>(niBHV) Bedrijfshulpverlening voor particuleren en bedrijven</span>
                        </Segment>
                    </Link>
                    <Link to='/cursus/ehbo-aan-kinderen'>
                        <Segment>
                            <CourseImage src={ImageEhboKinderen}/>
                            <h3 className="content-title">EHBO aan kinderen</h3>
                            <span>Hoe help je een kind in nood</span>
                        </Segment>
                    </Link>
                    <Link to='/cursus/eerste-hulp-bij-drank-en-drugs'>
                        <Segment>
                            <CourseImage src={ImageEhbdd}/>
                            <h3 className="content-title">EHBDD</h3>
                            <span>Eerste hulp bij drank & drugs</span>
                        </Segment>
                    </Link>
                    <Link to='/cursus/basic-life-support'>
                        <Segment>
                            <CourseImage src={ImageBsl}/>
                            <h3 className="content-title">BLS Basic life support</h3>
                            <span>Beter bekend als reanimeren, inclusief AED</span>
                        </Segment>
                    </Link>
                    {/*<Link to='/cursus/pediatric-basic-life-support'>*/}
                    {/*    <Segment>*/}
                    {/*        <CourseImage src={ImagePbsl}/>*/}
                    {/*        <h3 className="content-title">PBLS Pediatric Basic life support</h3>*/}
                    {/*        <span>Gericht op reanimatie aan kinderen en baby's</span>*/}
                    {/*    </Segment>*/}
                    {/*</Link>*/}
                    {/*<Link to='/cursus/wandelletsel'>*/}
                    {/*    <Segment>*/}
                    {/*        <CourseImage src={ImageWandelletsel}/>*/}
                    {/*        <h3 className="content-title">Eerste Hulp bij Wandelletsel</h3>*/}
                    {/*        <span>Gerichte cursus voor de behandeling van blaren</span>*/}
                    {/*    </Segment>*/}
                    {/*</Link>*/}
                    <Link to='/cursus/stop-de-bloeding'>
                        <Segment>
                            <CourseImage src={ImageStopBleeding}/>
                            <h3 className="content-title">Stop de bloeding</h3>
                            <span><q>- red een leven</q> de qoute waarbij iedere seconde telt</span>
                        </Segment>
                    </Link>
                </div>
            </div>
        </div>
    </SplitContent>
);
export default AllCourses;
