import React  from 'react';
import SplitContent from "../../../components/content/SplitContent";
import { Helmet } from "react-helmet";

const ContentTitle = "Privacy beleid";
const PageTitle = 'Privacy beleid | ACR-Opleidingen.nl';
const MetaDescription = 'ACR-opleidingen.nl: Privacy beleid';

const PrivacyBeleid = () => (
    <SplitContent>
        <Helmet>
            <title>{PageTitle}</title>
            <meta name="description" content={MetaDescription} />
        </Helmet>
        <h4>{ContentTitle}</h4>
        <p>
            <strong>Jouw privacy bij ACR-opleidingen.nl</strong>
        </p>
        <p>
            Dit privacybeleid is voor het laatste gewijzigd op 04 januari 2020.
        </p>
        <p>
            ACR-opleidingen.nl, gevestigd in Hoogmeer 25-11, 6605 CK Wijchen, (hierna: “ACR-opleidingen”, “ons”
            of “wij”), respecteert de privacy van de bezoekers van onze website www.acr-opleidingen.nl
            en de gebruikers van de mobiele applicatie (hierna: de “Website(s) of Applicatie(s)”) en van haar klanten
            die via deze Website(s) of Applicatie(s) een bestelling plaatsen. In dit Privacybeleid wordt uiteengezet
            welke informatie wij van jou als bezoeker van onze Website(s) en als klant verzamelen en hoe wij deze
            informatie verder gebruiken en beschermen.
        </p>
        <p>
            <strong>Toepassing van het Privacybeleid</strong>
        </p>
        <p>
            Dit Privacybeleid is van toepassing op elk(e) Websitebezoek, transactie of overeenkomst die via een
            Website of de Applicatie wordt overeengekomen. Alle informatie, waaronder je persoonsgegevens, die
            wij over jou verzamelen gedurende jouw bezoek en/of gebruik van onze Website(s) of Applicatie(s),
            zal worden behandeld in overeenstemming met dit beleid en de hierin beschreven doeleinden met betrekking
            tot de verwerking van je persoonsgegevens. Dit Privacybeleid geldt niet voor websites van andere bedrijven
            die een link naar onze Website(s) of Applicatie(s) bevatten of waarvan een link op onze Website(s) staat.
        </p>

        <p>
            <strong>Gegevens die wij verzamelen</strong>
        </p>
        <p>
            Op dit moment van schrijven verzamelen wij geen gegevens via onze website.
        </p>

        <p>
            <strong>Wijzigingen en vragen</strong>
        </p>
        <p>
            We behouden ons het recht voor om dit Privacybeleid aan te passen. We zullen die wijzigingen op onze
            Website(s) publiceren, zodat je altijd op de hoogte bent van de gegevens die we verzamelen, de wijze
            waarop deze gegevens door ons wordt gebruikt en beschermd en de omstandigheden waaronder wij je gegevens
            aan derden verstrekken en/of openbaar maken.
        </p>
        <p>
            Als je nog vragen hebt over jouw gegevens of dit Privacybeleid, dan kun je altijd contact opnemen met
            onze Klantenservice. Dit kan via de Website (klik hiervoor op de link 'Contact') of per telefoon
            06 1986 0230. Stuur je ons liever een bericht, dan kan dit via e-mail naar info@acr-opleidingen.nl of per
            brief naar Hoogmeer 25-11, 6605 CK Wijchen.
        </p>
    </SplitContent>
);
export default PrivacyBeleid;
