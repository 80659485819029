import React  from 'react';
import SplitContent from "../../../components/content/SplitContent";
import { Helmet } from "react-helmet";

const CourseName = "Pediatric Basic Life Support 'PBLS'";
const CoursePageTitle = `Cursus: ${CourseName} | ACR-Opleidingen.nl`;
const CourseMetaDescription = `ACR-opleidingen.nl: ${CourseName}`;

const CoursePbls = () => (
    <SplitContent>
        <Helmet>
            <title>{CoursePageTitle}</title>
            <meta name="description" content={CourseMetaDescription} />
        </Helmet>
        <h4>{CourseName}</h4>

    </SplitContent>
);
export default CoursePbls;
