import React, { Component } from 'react';
import { Container, Grid, Image } from "semantic-ui-react";
import brand_ehbdd from "../../images/brands/ehbdd.png";
import brand_lotus from "../../images/brands/lotus.png";
import brand_nrr from "../../images/brands/nrr.png";
import brand_oranje_kruis from "../../images/brands/oranje-kruis.png";
import brand_rode_kruis from "../../images/brands/rode-kruis.png";
import brand_sbrel from "../../images/brands/sbrel.png";


export default class Brand extends Component {
    render() {
        return (
            <div className='footer-brands'>
                <Container>
                    <p><strong>Wij zijn verbonden en volgen de richtlijnen van:</strong></p>
                    <Grid columns='equal'>
                        <Grid.Column ><Image centered src={brand_ehbdd} /></Grid.Column>
                        <Grid.Column ><Image centered src={brand_lotus} /></Grid.Column>
                        <Grid.Column><Image centered src={brand_nrr} /></Grid.Column>
                        <Grid.Column><Image centered src={brand_oranje_kruis} /></Grid.Column>
                        <Grid.Column><Image centered src={brand_rode_kruis} /></Grid.Column>
                        <Grid.Column><Image centered src={brand_sbrel} /></Grid.Column>
                    </Grid>
                </Container>
            </div>
        );
    }
}
