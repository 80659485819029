import React, { Component } from 'react';
import { Container, Grid, Image } from "semantic-ui-react";
import logo from "../../images/acr1.png";
import { Link } from "react-router-dom";
import '../../css/components/footer/Footer.scss';

export default class Footer extends Component {
    render() {
        return (
            <div className='footer-footer'>
                <Container>
                    <Grid columns='equal' className="middle aligned">
                        <Grid.Column width={3}>
                            <Image src={logo} className="logo-header" size='mini' />
                        </Grid.Column>
                        <Grid.Column>
                            <ol>
                                <li><Link to="/algemene-voorwaarden">Algemene voorwaarden</Link></li>
                                <li><Link to="/privacy-beleid">Privacy beleid</Link></li>
                                <li>Hoogmeer 25-11</li>
                                <li>6605 CK Wijchen</li>
                                <li>Tel. (+31) (0)6 1986 0230</li>
                                <li><a href="mailto:info@acr-opleidingen.nl">info@acr-opleidingen.nl</a></li>
                            </ol>
                        </Grid.Column>
                    </Grid>
                </Container>
            </div>
        );
    }
}
