import React  from 'react';
import SplitContent from "../../../components/content/SplitContent";
import { Helmet } from "react-helmet";
import Questions from "../../../components/content/Questions";
import Signup from "../../../components/content/Signup";

const CourseName = "“Een ongeluk zit in een klein hoekje”";
const CoursePageTitle = `Cursus: ${CourseName} | ACR-Opleidingen.nl`;
const CourseMetaDescription = `ACR-opleidingen.nl: ${CourseName}`;

const CourseBhvBasis = () => (
    <SplitContent>
        <Helmet>
            <title>{CoursePageTitle}</title>
            <meta name="description" content={CourseMetaDescription} />
        </Helmet>
        <h4>{CourseName}</h4>
        <div className="twelve wide column">
            <p>Een ongeluk zit in een
            klein hoekje: Dit spreekwoord geeft aan dat een ongeval en/of brand overal kan voorkomen. Van een werkgever
            wordt verwacht dat hij de risico’s in zijn bedrijf in kaart brengt door een Risico inventarisatie en
            Evaluatie uit te voeren. Wij bieden verschillende cursussen die kunnen helpen bij het voorkomen en/of het
            handelen bij calamiteiten.</p><p>In 2 dagen tijd wordt u opgeleid tot BHVér.</p><p>Prijzen op aanvraag (BHV
            Basis al vanaf €175,00 p/p ex btw)</p>
            <Signup />
            <Questions />
        </div>

    </SplitContent>
);
export default CourseBhvBasis;
