import React, { Component }  from 'react';


import {Link} from "react-router-dom";

export default class Questions extends Component {
    render() {
        return (
            <>
                <p>
                    Heeft u nog vragen of opmerkingen? Stuur dan een e-mail naar <a target="_blank" rel="noopener noreferrer" href="mailto:info@acr-opleidingen.nl">info@acr-opleidingen.nl</a>&nbsp;
                    of telefonisch voor al je vragen naar 06 1986 0230. Naar <Link to="/contact">contactpagina</Link>.
                </p>
             </>
        );
    }
}
