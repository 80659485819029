import React, { Component } from 'react';
import { Segment, Header, Icon, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default class RightCol extends Component {
    render() {
        return (
            <>
                <Segment>
                    <Header as='h5' >Persoonlijk advies</Header>
                    <div className="segment-block">
                        <div className="col-left">
                            <Icon color='green' name='phone' />
                        </div>
                        <div className="col-right">
                            <div>
                                <span className="content-title">06 1986 0230</span>
                            </div>
                            {/*<div>*/}
                            {/*    <Button primary>Offerte aanvragen</Button>*/}
                            {/*</div>*/}
                            {/*<div>*/}
                            {/*    <Link to="/terugbelverzoek" title="foo">Stuur een terugbelverzoek</Link>*/}
                            {/*</div>*/}
                            <div>
                                <a target="_blank" rel="noopener noreferrer" href="mailto:info@acr-opleidingen.nl">Of stuur een e-mail</a>
                            </div>
                        </div>
                    </div>

                    <Divider />

                    <div className="segment-block">
                        <div className="col-left">
                            <Icon color='green' name='time' />
                        </div>
                        <div className="col-right">
                            <div>
                                <span className="content-title">Beschikbaar</span>
                            </div>
                            <div>
                                <Link to="/openingstijden">Bekijk onze openingstijden</Link>
                            </div>
                            <div>
                                <Link to="/locaties">Locaties</Link>
                            </div>
                        </div>
                    </div>

                    <Divider />

                    <div className="segment-block">
                        <div className="col-left">
                            <Icon color='green' name='question circle' />
                        </div>
                        <div className="col-right">
                            <div>
                                <span className="content-title">Vraag &amp; antwoord</span>
                            </div>
                            {/*<div>*/}
                            {/*    <Link to="/meestgestelde-vragen">Meestgestelde vragen</Link>*/}
                            {/*</div>*/}
                            <div>
                                <Link to="/cursus-vergoeding">Cursus vergoeding</Link>
                            </div>
                        </div>
                    </div>
                </Segment>

                {/*<Segment>*/}
                {/*    <Header as='h5' >Onze voordelen</Header>*/}
                {/*    <div className="segment-block">*/}
                {/*        <div className="col-left no-width">*/}
                {/*            <div><Icon className="acrblue" name='eye' />&nbsp; Keurmerk opledingen</div>*/}
                {/*            <div><Icon className="acrblue" name='paper plane' />&nbsp; Diploma's & Certificaten</div>*/}
                {/*            <div><Icon className="acrblue" name='location arrow' />&nbsp; Lessen op locatie mogelijk</div>*/}
                {/*            <div><Icon className="acrblue" name='cogs' />&nbsp; Trainingen opmaat</div>*/}
                {/*            <div><Icon className="acrblue" name='users' />&nbsp; Gecertificeerd personeel</div>*/}
                {/*        </div>*/}
                {/*        <div className="col-left">&nbsp;</div>*/}
                {/*        <div className='col-right'>*/}
                {/*            <div><Link to="/alle-voordelen">Alle voordelen</Link></div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</Segment>*/}
            </>
        );
    }
}
