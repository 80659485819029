import React, { Component } from 'react';
import { Link } from "react-router-dom";

export default class MobileMenu extends Component {
    render() {
        return (
            <>
                <div className="menu-block">
                    <div>
                        Specials
                    </div>
                    <div>
                        <Link to="/lotus-specialist">LOTUS® Specialist</Link>
                        <Link to="/brandbestrijding-en-ontruiming">Brandbestrijding en ontruiming</Link>
                    </div>
                </div>
                <div className="menu-block">
                    <div>
                        Persoonlijk advies
                    </div>
                    <div>
                        {/*<Link to="/offerte-aanvraag">Offerte aanvragen</Link>*/}
                        {/*<Link to="/terugbelverzoek">Terugbelverzoek</Link>*/}
                        <Link to="/openingstijden">Openingstijden</Link>
                        {/*<Link to="/meestgestelde-vragen">Meestgestelde vragen</Link>*/}
                        <Link to="/locaties">Locaties</Link>
                        {/*<Link to="/alle-voordelen">Alle voordelen</Link>*/}
                        <Link to="/cursus-vergoeding">Curus vergoeding</Link>
                    </div>
                </div>
                <div className="menu-block">
                    <div>
                        Populaire cursussen
                    </div>
                    <div>
                        <Link to="/cursus/basis-cursus-ehbo">EHBO basis cursus</Link>
                        <Link to="/cursus/basis-cursus-bhv">BHV basis cursus</Link>
                        <Link to="/cursus/ehbo-aan-kinderen">EHBO aan kinderen</Link>
                        <Link to="/cursus/eerste-hulp-bij-drank-en-drugs">EHBDD Drank & Drugs</Link>
                        <Link to="/cursus/basic-life-support">BLS Basic life support</Link>
                        {/*<Link to="/cursus/pediatric-basic-life-support">PBLS Pediatric Basic life support</Link>*/}
                        {/*<Link to="/cursus/wandelletsel">Eerste Hulp bij Wandelletsel</Link>*/}
                        <Link to="/cursus/stop-de-bloeding">Stop de bloeding</Link>
                    </div>
                </div>
                {/*<div className="menu-block">*/}
                {/*    <div>*/}
                {/*        Nieuws*/}
                {/*    </div>*/}
                {/*    <div>*/}
                {/*        <Link to="/nieuws/grote-antiterreuroefening-keukenhof">Grote antiterreuroefening keukenhof</Link>*/}
                {/*        <Link to="/nieuws/bericht2">Het Rode Kruis start een petitie voor EHBO in het onderwijs</Link>*/}
                {/*        <Link to="/nieuws/bericht3">Bericht 3</Link>*/}
                {/*        <Link to="/nieuws/bericht4">Bericht 4</Link>*/}
                {/*        <Link to="/nieuws/bericht5">Bericht 5</Link>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="menu-block">*/}
                {/*    <div>*/}
                {/*        Blogs*/}
                {/*    </div>*/}
                {/*    <div>*/}
                {/*        <Link to="/blog/blog1">Blog 1</Link>*/}
                {/*        <Link to="/blog/blog2">Blog 2</Link>*/}
                {/*        <Link to="/blog/blog3">Blog 3</Link>*/}
                {/*        <Link to="/blog/blog4">Blog 4</Link>*/}
                {/*        <Link to="/blog/blog5">Blog 5</Link>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="menu-block">
                    <div>
                        Klantinformatie
                    </div>
                    <div>
                        {/*<Link to="/over-ons">Over ACR-Opleidingen.nl</Link>*/}
                        {/*<Link to="/klantinformatie">Klantinformatie</Link>*/}
                        {/*<Link to="/onze-instructeurs">Onze instructeurs</Link>*/}
                        {/*<Link to="/partners">Partners</Link>*/}
                        {/*<Link to="/vacatures">Vacatures</Link>*/}
                        <Link to="/contact">Contact</Link>
                        <Link to="/openingstijden">Contact</Link>
                    </div>
                </div>
            </>

        );
    }
}
