import React, { Component }  from 'react';
// import {Link} from "react-router-dom";

export default class Signup extends Component {
    render() {
        return (
            <>
                <p>
                    <p>
                        <strong>Aanmelden?</strong><br />
                        Via telefoon: 06 1986 0230 om te vragen naar de mogelijkheden en beschikbaarheid.<br />
                        Via e-mail: Met daarin de naam van de cursus en geef daarbij aan of je deze op onze
                        locatie in Wijchen of op een externe locatie naar keuze wilt volgen.
                    </p>
                    <p>
                        Doorgaans reageren wij nog voor het einde van de eerst volgende werkdag.
                    </p>
                </p>
             </>
        );
    }
}
