import React  from 'react';
import SplitContent from "../../../components/content/SplitContent";
import {Helmet} from "react-helmet";

const NoMatch = () => (
    <SplitContent>
        <Helmet>
            <title>Pagina niet gevonden (404) | ACR-Opleidingen.nl</title>
            <meta name="description" content="ACR-opleidingen.nl: de opgevraagde pagina is niet beschikbaar" />
        </Helmet>
        Oops, deze pagina bestaat niet (meer).
    </SplitContent>
);
export default NoMatch;
