import React, { Component } from 'react';
import TopBar from "../header/TopBar";
import SearchSection from "../header/SearchSection";
import HeaderMenu from "../header/HeaderMenu";
import Banner from "../header/Banner";
// import NewsLetter from "../footer/NewsLetter";
// import QuickLinks from "../footer/QuickLinks";
import Brand from "../footer/Brand";
import Footer from "../footer/Footer";
import {Container, Segment} from "semantic-ui-react";
import { withRouter } from 'react-router-dom';
import SitePageNavigation from "./SitePageNavigation";

export class MainTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
            isMobile: (window.innerWidth <= 767),
            isTablet: (window.innerWidth > 767 && window.innerWidth <= 768),
            isLaptop: (window.innerWidth > 768 && window.innerWidth <= 1024),
            isComputer: (window.innerWidth > 1024),
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0,0);
        }
    }

    updateWindowDimensions() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
            isMobile: (window.innerWidth <= 767),
            isTablet: (window.innerWidth > 767 && window.innerWidth <= 768),
            isLaptop: (window.innerWidth > 768 && window.innerWidth <= 1024),
            isComputer: (window.innerWidth > 1024),
        });
    }

    render() {

        const { isMobile, isTablet, isLaptop, isComputer } = this.state;

        return (
            <div>
                <TopBar isTablet={isTablet} isLaptop={isLaptop} isComputer={isComputer} />
                <SearchSection />
                {(!isMobile) ? <><HeaderMenu/><Banner /> </> : ''}

                <SitePageNavigation />

                <Container className="content">
                    <Segment>
                        {this.props.children}
                    </Segment>
                </Container>
                <footer>
                    {/*<NewsLetter />*/}
                    {/*<QuickLinks />*/}
                    <Brand/>
                    <Footer/>
                </footer>
            </div>
        );
    }
}

export default withRouter(props => <MainTemplate {...props}/>);
