import React  from 'react';
import SplitContent from "../../../components/content/SplitContent";
import { Helmet } from "react-helmet";
// import {Link} from "react-router-dom";
import Questions from "../../../components/content/Questions";

const ContentTitle = "Cursus vergoeding";
const PageTitle = 'Cursus vergoeding | ACR-Opleidingen.nl';
const MetaDescription = 'ACR-opleidingen.nl: Cursus vergoeding tot 100 procent vergoed';

const CursusVergoeding = () => (
    <SplitContent>
        <Helmet>
            <title>{PageTitle}</title>
            <meta name="description" content={MetaDescription} />
        </Helmet>
        <h4>{ContentTitle}</h4>

        <div className="twelve wide column">
            <p>De meeste zorgverzekeraars vergoeden een cursus EHBO.</p>
            <p>Voor onze andere cursussen kunt u het beste even navraag doen bij uw zorgverzekeraar.<br /></p>
            <p>
                Check het even bij Independer!
            </p>
            <Questions />
        </div>
    </SplitContent>
);
export default CursusVergoeding;
