import React  from 'react';
import SplitContent from "../../../components/content/SplitContent";
import { Helmet } from "react-helmet";
import Signup from "../../../components/content/Signup";
import Questions from "../../../components/content/Questions";

const CourseName = "Eerste hulp bij drank & drugs 'EHBDD'";
const CoursePageTitle = `Cursus: ${CourseName} | ACR-Opleidingen.nl`;
const CourseMetaDescription = `ACR-opleidingen.nl: ${CourseName}`;

const CourseEhbdd = () => (
    <SplitContent>
        <Helmet>
            <title>{CoursePageTitle}</title>
            <meta name="description" content={CourseMetaDescription} />
        </Helmet>
        <h4>{CourseName}</h4>
        <div className="twelve wide column"><p>Wat te doen bij
            verdenking op drank en/of drugs gebruik en het slachtoffer heeft medische hulp nodig?</p><p>Weet U hoe te
            handelen? Wanneer is het uitgewerkt? Wat is er gebruikt? Hoe herken ik dat?</p><p>In onze workshop krijgt u
            daar antwoorden op !</p><p>Wij laten u kennis maken met de wondere wereld van alcohol en drugs, zonder
            hierbij zelf iets te gebruiken , maar hoe kan ik het herkennen en wat kan ik er mee!</p><p>Het betreft 1
            avond of middag.</p>

            <Signup />
            <Questions />

        </div>
    </SplitContent>
);
export default CourseEhbdd;
