import React  from 'react';
import SplitContent from "../../../components/content/SplitContent";
import { Helmet } from "react-helmet";

const ContentTitle = "Één van onze cursuslocaties of Incompany?";
const PageTitle = 'Locaties | Incompany ACR-Opleidingen.nl';
const MetaDescription = 'ACR-opleidingen.nl: Locaties en Incompany ';

const Locaties = () => (
    <SplitContent>
        <Helmet>
            <title>{PageTitle}</title>
            <meta name="description" content={MetaDescription} />
        </Helmet>
        <h4>{ContentTitle}</h4>
        <p>
            Doordat bijna alle trainingen op locatie plaatsvinden hebben wij zelf nog geen vaste trainingsruimte beschikbaar.
        </p>
        <p>
            Voor aanvang van je cursus ontvang je de locatie per e-mail of post.
        </p>
    </SplitContent>
);
export default Locaties;
