import React  from 'react';
import SplitContent from "../../../components/content/SplitContent";
import { Helmet } from "react-helmet";
import {Grid} from "semantic-ui-react";
// import RightCol from "../../../components/content/right-column/RightCol";

const ContentTitle = "Openingstijden en bereikbaarheid";
const PageTitle = 'Openingstijden en bereikbaarheid | ACR-Opleidingen.nl';
const MetaDescription = 'ACR-opleidingen.nl: Openingstijden en bereikbaarheid';

const Openingstijden = () => (
    <SplitContent>
        <Helmet>
            <title>{PageTitle}</title>
            <meta name="description" content={MetaDescription} />
        </Helmet>
        <h4>{ContentTitle}</h4>

        <Grid>
            <Grid.Column width={12}>

                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <p>
                                <strong>Tefoonnummer</strong>
                            </p>
                        </Grid.Column>
                        <Grid.Column width={8} >
                            Tel. +31 (0)6 1986 0230
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <p>
                                <strong>Postadres</strong>
                            </p>
                        </Grid.Column>
                        <Grid.Column width={8} >
                            <p>
                                <i>Hoogmeer 25-11</i><br />
                                <i>6605 CK</i><br />
                                <i>Wijchen</i><br />
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <p>
                                <strong>Email ons</strong><br />
                                Algemeen: <a target="_blank" rel="noopener noreferrer" href="mailto:info@acr-opleidingen.nl">info@acr-opleidingen.nl</a>
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>


            </Grid.Column>
            <Grid.Column width={4} >

            </Grid.Column>
        </Grid>


    </SplitContent>
);
export default Openingstijden;
