import React  from 'react';
import SplitContent from "../../../components/content/SplitContent";
import { Helmet } from "react-helmet";

const ContentTitle = "Over ACR-Opleidingen.nl";
const PageTitle = 'Over ons | ACR-Opleidingen.nl';
const MetaDescription = 'ACR-opleidingen.nl: over ons';

const OverOns = () => (
    <SplitContent>
        <Helmet>
            <title>{PageTitle}</title>
            <meta name="description" content={MetaDescription} />
        </Helmet>
        <h4>{ContentTitle}</h4>
        <p>
            <span>De inhoud van deze pagina is op dit moment nog niet beschikbaar</span>
        </p>
    </SplitContent>
);
export default OverOns;
