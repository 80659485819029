import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon } from "semantic-ui-react";

export default class TopBar extends Component {

    render() {
        // const { isTablet, isLaptop, isComputer } = this.props;

        return (
            <div className="top-bar">
                <Grid container>
                    <Grid.Column align='center'>
                        <Icon size='small' color='orange' name='warning circle'/>
                        Aan onze website wordt nog hard gewerkt! Voor vragen en advies kan je contact opnemen met tel: 06 1986 0230
                    </Grid.Column>
                </Grid>
            </div>
        );


        // if (isTablet) {
        //     return (
        //         <div className="top-bar mobile or lower hidden">
        //             <Grid container columns='three'>
        //                 <Grid.Column align='left'><Icon size='small' color='green' name='check'/>Altijd 100% tevreden</Grid.Column>
        //                 <Grid.Column align='center'><Icon size='small' color='green' name='check'/>Diploma's &amp; Certificaten</Grid.Column>
        //                 <Grid.Column align='right'><Icon size='small' color='green' name='check'/>Trainingen opmaat</Grid.Column>
        //             </Grid>
        //         </div>
        //     );
        // }
        //
        // if (isLaptop) {
        //     return (
        //         <div className="top-bar">
        //             <Grid container columns='four'>
        //                 <Grid.Column align='left'><Icon size='small' color='green' name='check'/>Altijd 100% tevreden</Grid.Column>
        //                 <Grid.Column align='center'><Icon size='small' color='green' name='check'/>Diploma's &amp; Certificaten</Grid.Column>
        //                 <Grid.Column align='center'><Icon size='small' color='green' name='check'/>Groepslessen</Grid.Column>
        //                 <Grid.Column align='right'><Icon size='small' color='green' name='check'/>Trainingen opmaat</Grid.Column>
        //             </Grid>
        //         </div>
        //     );
        // }
        //
        // if (isComputer) {
        //     return (
        //         <div className="top-bar">
        //             <Grid container columns='five'>
        //                 <Grid.Column align='left'><Icon size='small' color='green' name='check'/>Altijd 100% tevreden</Grid.Column>
        //                 <Grid.Column align='center'><Icon size='small' color='green' name='check'/>Diploma's &amp; Certificaten</Grid.Column>
        //                 <Grid.Column align='center'><Icon size='small' color='green' name='check'/>Groepslessen</Grid.Column>
        //                 <Grid.Column align='center'><Icon size='small' color='green' name='check'/>Gecertificeerde trainers</Grid.Column>
        //                 <Grid.Column align='right'><Icon size='small' color='green' name='check'/>Trainingen opmaat</Grid.Column>
        //             </Grid>
        //         </div>
        //     );
        // }
        // return <></>;
    }
}

TopBar.propTypes = {
    isTablet: PropTypes.bool.isRequired,
    isLaptop: PropTypes.bool.isRequired,
    isComputer: PropTypes.bool.isRequired,
};
