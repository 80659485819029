import React, { Component } from 'react';
import { Container, Grid, Image, Icon, Dimmer } from "semantic-ui-react";
import logo from '../../images/acr1.png';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import MobileMenu from "./MobileMenu";

export class SearchSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menuActive: false,
            lastVerticalScrollPosition: 0,
            lastTopScrollPosition: 0,
            lastBottomScrollPosition: 0,
            showSearchSection: '',
            scrollingUp: false,
            scrollingDown: false,
        };

        this.handleOpenMenu = this.handleOpenMenu.bind(this);
        this.handleCloseMenu = this.handleCloseMenu.bind(this);
        this.showScroll = this.showScroll.bind(this);
    }

    static handleLogoClick() {
        window.scrollTo(0,0);
    }

    handleCloseMenu() {
        window.scrollTo(0,this.state.lastVerticalScrollPosition);
        this.setState({menuActive: false});
        const scrollPos = window.pageYOffset || document.documentElement.scrollTop;

        this.setState({showSearchSection: (scrollPos > 64) ? 'search-section-wrapper' : ''});
    }

    handleOpenMenu() {
        if (this.state.menuActive) return this.handleCloseMenu();
        window.scrollTo(0,0);
        this.setState({showSearchSection:'search-section-wrapper wrapper-open'});
        this.setState({menuActive: true});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({menuActive:false});
            this.setState({showSearchSection:''});
        }
    }

    showScroll() {
        if (this.state.menuActive) {
            return;
        }

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (scrollTop <= 0) {
            this.setState({
                scrollingUp:false,
                showSearchSection: ''
            });
            return;
        }

        if (scrollTop < this.state.lastVerticalScrollPosition) {
            const difference = Math.abs(scrollTop - this.state.lastBottomScrollPosition);
            this.setState({scrollingDown:false});
            if (!this.state.scrollingUp) {
                this.setState({scrollingUp:true});
                this.setState({lastBottomScrollPosition: scrollTop});
            } else if (difference > 200) {
                this.setState({showSearchSection: 'search-section-wrapper'});
            }
        } else {
            const difference = Math.abs(this.state.lastTopScrollPosition - scrollTop);
            this.setState({scrollingUp:false});
            if (!this.state.scrollingDown) {
                this.setState({scrollingDown:true});
                this.setState({lastTopScrollPosition: scrollTop});
            } else if (difference > 100) {
                this.setState({showSearchSection: ''});
            }
        }

        this.setState({lastVerticalScrollPosition: scrollTop <= 0 ? 0 : scrollTop});
    }

    componentDidMount() {
        window.addEventListener('scroll', this.showScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.showScroll);
    }

    render() {
        return (
            <>
                <div className={this.state.showSearchSection}>
                    <div className="search-section">
                        <Container>
                            <Grid>
                                <Grid.Column mobile={8} tablet={3} computer={3}>
                                    <div>
                                        <Image src={logo} to="/" as={Link} onClick={this.constructor.handleLogoClick}/>
                                    </div>
                                </Grid.Column>
                                <Grid.Column className="mobile or lower hidden" tablet={10} computer={10}>
                                    {/*<Input size='big' icon='search' placeholder='Zoeken...'/>*/}
                                    <div className="site-title">
                                        ACR-opleidingen.nl<br />
                                    </div>
                                    <div className="site-description mobile or lower hidden"><strong>EHBO en BHV cursussen / trainingen op maat</strong></div>
                                </Grid.Column>
                                <Grid.Column mobile={8} tablet={3} computer={3} align='right'>
                                    <div className="col">
                                        {/*<Icon fitted name='user' size='large' color="blue"/>*/}
                                        {/*<span className="mobile hidden tablet hidden">&nbsp; Login / Register</span>*/}
                                    </div>
                                </Grid.Column>
                            </Grid>
                        </Container>
                    </div>
                    <div className="search-section-mobile mobile only">
                        <Container>
                            <Grid>
                                <Grid.Column width={5}>
                                    <div onClick={this.handleOpenMenu}>
                                        <Icon fitted name='bars' size='large' color="blue"/> &nbsp; <b>Menu</b>
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={11}>
                                    {/*<Input  icon='search' placeholder='Zoeken...'/>*/}
                                    <div className="site-title mobiles">ACR Opleidingen.nl</div>
                                </Grid.Column>
                            </Grid>
                        </Container>
                        <div className={this.state.menuActive ? 'menu-mobile': 'menu-mobile hidden'}>
                            <MobileMenu />
                        </div>
                    </div>
                </div>
                <Dimmer active={this.state.menuActive}/>
            </>
        );
    }
}
export default withRouter(props => <SearchSection {...props}/>);
