import React, { Component } from 'react';
import {Container} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';

export class SitePageNavigation extends Component {
    render () {
        const path = this.props.location.pathname;
        if (path === '/') return '';
        return (
            <Container className="site-page-navation">
                {
                    path.split("/").map((part, index) => {
                        return (part === "") ?
                            <span key="0"><Link to="/">Home</Link></span> :
                            (path.split("/").length-1 !== index) ?
                                <span key={part}> / <Link to={`/${part}`}>{part}</Link></span> :
                                <span className="last" key={part}> / {part.replace(/-/g," ")}</span>
                    })
                }
            </Container>
        );
    }
}

export default withRouter(props => <SitePageNavigation {...props}/>)
