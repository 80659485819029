import React, { Component } from 'react';
import { Divider, Icon, List, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import ImageEhbo from '../images/courses/ehbo.jpg';
import ImageBHV from '../images/courses/bhv.jpg';
import ImageEhboKinderen from '../images/courses/ehbo-kinderen.jpg';
import ImageEhbdd from '../images/courses/ehbdd.jpg';
import ImageBsl from '../images/courses/bls.jpg';
// import ImagePbsl from '../images/courses/pbls.jpg';
// import ImageWandelletsel from '../images/courses/wandelletsel.jpg';
import ImageStopBleeding from '../images/courses/stop-bleeding.jpg';
import CourseImage from "../components/content/main-content/CourseImage";
import SplitContent from "../components/content/SplitContent";

// import ImageKeukenHof from '../images/news/keukenhof-lisse-oefening.jpg';
// import ImageRodeKruis from '../images/news/rode-kruis.jpg';

export default class Home extends Component {
    render() {
        return (
            <SplitContent>
                <Helmet>
                    <title>EHBO en BHV cursussen / trainingen op maat | ACR-Opleidingen.nl</title>
                    <meta name="description" content="ACR-opleidingen.nl: regionale opleider voor EHBO & BHV omgeving Nijmegen en Wijchen" />
                </Helmet>
                <div className="content-products">
                    <h4>Populairste cursussen</h4>
                    <Divider />

                    <div className="course-blocks">
                        <Link to='/cursus/basis-cursus-ehbo'>
                            <Segment>
                                <CourseImage src={ImageEhbo}/>
                                <h3 className="content-title">EHBO basis cursus</h3>
                                <span>De basis cursus volgens Het Oranje Kruis</span>
                                {/*<span className="product-badge"><b>50%</b> partner voordeel</span>*/}
                            </Segment>
                        </Link>
                        <Link to='/cursus/basis-cursus-bhv'>
                            <Segment>
                                <CourseImage src={ImageBHV}/>
                                <h3 className="content-title">BHV basis cursus</h3>
                                <span>(niBHV) Bedrijfshulpverlening voor particuleren en bedrijven</span>
                            </Segment>
                        </Link>
                        <Link to='/cursus/ehbo-aan-kinderen'>
                            <Segment>
                                <CourseImage src={ImageEhboKinderen}/>
                                <h3 className="content-title">EHBO aan kinderen</h3>
                                <span>Hoe help je een kind in nood</span>
                            </Segment>
                        </Link>
                        <Link to='/cursus/eerste-hulp-bij-drank-en-drugs'>
                            <Segment>
                                <CourseImage src={ImageEhbdd}/>
                                <h3 className="content-title">EHBDD</h3>
                                <span>Eerste hulp bij drank & drugs</span>
                            </Segment>
                        </Link>
                        <Link to='/cursus/basic-life-support'>
                            <Segment>
                                <CourseImage src={ImageBsl}/>
                                <h3 className="content-title">BLS Basic life support</h3>
                                <span>Beter bekend als reanimeren, inclusief AED</span>
                            </Segment>
                        </Link>
                        {/*<Link to='/cursus/pediatric-basic-life-support'>*/}
                        {/*    <Segment>*/}
                        {/*        <CourseImage src={ImagePbsl}/>*/}
                        {/*        <h3 className="content-title">PBLS Pediatric Basic life support</h3>*/}
                        {/*        <span>Gericht op reanimatie aan kinderen en baby's</span>*/}
                        {/*    </Segment>*/}
                        {/*</Link>*/}
                        {/*<Link to='/cursus/wandelletsel'>*/}
                        {/*    <Segment>*/}
                        {/*        <CourseImage src={ImageWandelletsel}/>*/}
                        {/*        <h3 className="content-title">Eerste Hulp bij Wandelletsel</h3>*/}
                        {/*        <span>Gerichte cursus voor de behandeling van blaren</span>*/}
                        {/*    </Segment>*/}
                        {/*</Link>*/}
                        <Link to='/cursus/stop-de-bloeding'>
                            <Segment>
                                <CourseImage src={ImageStopBleeding}/>
                                <h3 className="content-title">Stop de bloeding</h3>
                                <span><q>- red een leven</q> de qoute waarbij iedere seconde telt</span>
                            </Segment>
                        </Link>
                    </div>
                    <List>
                        <List.Item as={Link} to='/cursus'><Icon name='eye' /><span>Bekijk alle cursussen en trainingen</span></List.Item>
                    </List>
                </div>

                {/*<div className="promo-banner" />*/}

                {/*<div className="content-products">*/}
                {/*    <h4>Ons laatste nieuws</h4>*/}
                {/*    <Divider />*/}
                {/*    <div className="news-blocks">*/}
                {/*        <Link to='/nieuws/grote-antiterreuroefening-keukenhof'>*/}
                {/*            <Segment>*/}
                {/*                <Image src={ImageKeukenHof} centered/>*/}
                {/*                <h3 className="content-title">Grote antiterreuroefening keukenhof.</h3>*/}
                {/*                <span>In de keukenhof van lisse is vandaag een grote antiterreuroefening gehouden. Onze LOTUS in actie!</span>*/}
                {/*            </Segment>*/}
                {/*        </Link>*/}
                {/*        <Link to='/nieuws/bericht2'>*/}
                {/*            <Segment>*/}
                {/*                <Image src={ImageRodeKruis} centered />*/}
                {/*                <h3 className="content-title">Het Rode Kruis start een petitie voor EHBO in het onderwijs</h3>*/}
                {/*                <span>Het Rode Kruis start in samenwerking met Schok & Pomp en radio DJ Rob van Someren een petitie voor EHBO in het onderwijs.</span>*/}
                {/*            </Segment>*/}
                {/*        </Link>*/}
                {/*        <Link to='/nieuws/bericht3'>*/}
                {/*            <Segment>*/}
                {/*                <Image src='https://react.semantic-ui.com/images/wireframe/image.png' centered />*/}
                {/*                <h3 className="content-title">Missippi’s literacy program shows improvement.</h3>*/}
                {/*                <span>Bladie blaa de la blaa in de blaa wat random text word hier geplaats voor de opvulling van deze content</span>*/}
                {/*            </Segment>*/}
                {/*        </Link>*/}
                {/*        <Link to='/nieuws/bericht4'>*/}
                {/*            <Segment>*/}
                {/*                <Image src='https://react.semantic-ui.com/images/wireframe/image.png' centered />*/}
                {/*                <h3 className="content-title">Breathing oxygen linked to staying alive.</h3>*/}
                {/*                <span>Bladie blaa de la blaa in de blaa wat random text word hier geplaats voor de opvulling van deze content</span>*/}
                {/*            </Segment>*/}
                {/*        </Link>*/}
                {/*    </div>*/}
                {/*    <List>*/}
                {/*        <List.Item as={Link} to='/nieuws'><Icon name='eye' /><span>Bekijk alle nieuwsberichten</span></List.Item>*/}
                {/*    </List>*/}
                {/*</div>*/}
            </SplitContent>
        );
    }
}
