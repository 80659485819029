import React  from 'react';
import SplitContent from "../../../components/content/SplitContent";
import { Helmet } from "react-helmet";

const ContentTitle = "Grote antiterreuroefening keukenhof";
const CoursePageTitle = `${ContentTitle} | ACR-Opleidingen.nl`;
const CourseMetaDescription = `ACR-opleidingen.nl: ${ContentTitle}`;

const News1 = () => (
    <SplitContent>
        <Helmet>
            <title>{CoursePageTitle}</title>
            <meta name="description" content={CourseMetaDescription} />
        </Helmet>
        <h4>{ContentTitle}</h4>
        <p>
            <span>
                Woensdag, 22 mei 2019. In de keukenhof van lisse is vandaag een grote antiterreuroefening gehouden. Er werd een levensechte oefening gehouden waarbij meerdere hulpdiensten betrokken bij waren.

                Deze oefening bestond uit het naspelen van een helaas relasitsche <q>terroristische aanslag</q> waarbij waarbij meerdere slachtoffers zijn gevallen.
            </span>
        </p>
    </SplitContent>
);
export default News1;
