import React  from 'react';
import SplitContent from "../../../components/content/SplitContent";
import { Helmet } from "react-helmet";
import Signup from "../../../components/content/Signup";
import Questions from "../../../components/content/Questions";

const CourseName = "Basis cursus eerste hulp bij ongeval 'EHBO'";
const CoursePageTitle = `Cursus: ${CourseName} | ACR-Opleidingen.nl`;
const CourseMetaDescription = `ACR-opleidingen.nl: ${CourseName}`;

const CourseEhboBasis = () => (
    <SplitContent>
        <Helmet>
            <title>{CoursePageTitle}</title>
            <meta name="description" content={CourseMetaDescription} />
        </Helmet>
        <h4>{CourseName}</h4>

        <div className="twelve wide column">
            <p>U zit in een
            vergadering en plotseling zakt één van uw collega’s in elkaar. Weet u dan wat u moet doen? U bent aan het
            klussen, uw stanleymes schiet uit en u raakt uw slagader. Wat nu? Het snel en kundig kunnen toepassen van
            EHBO kan levens redden, want soms duurt wachten op de ambulance te lang!</p><p>Deze opleiding is geschikt
            voor iedereen die zich privé of beroepsmatig wil scholen in eerste hulpvaardigheden.</p><p>Hoe is de EHBO
            cursus opgebouwd?</p><p>De cursus is verdeeld over drie dagen. Op dag drie doet u een praktijkexamen,
            waarbij u in verschillende situaties moet laten zien dat u effectief eerste hulp kunt verlenen. Eén van de
            examenonderdelen is daarom ook het reanimeren van een slachtoffer met behulp van een AED.</p><p>Wat kan ik
            met mijn EHBO-diploma?</p><p>Als u het diploma heeft gehaald, dan heeft u onder andere voldoende kennis en
            vaardigheden om:</p>
            <ul>
                <li>eerst voor veiligheid te zorgen, voor uzelf, het slachtoffer en eventuele omstanders;</li>
                <li>om gegevens te verzamelen om erachter te komen wat er is gebeurd en wat het slachtoffermankeert</li>
                <li>om eerste hulp te verlenen bij stoornissen in het bewustzijn en/ of ademhaling</li>
                <li>om de ademhaling weer op gang te brengen en te houden;</li>
                <li>om eerste hulp te verlenen bij actieve bloedingen, uitwendige wonden, verstuikingen,</li>
                <li>kneuzingen, botbreuken, brandwonden en letsel door elektriciteit;</li>
                <li>om het ontstaan of het erger worden van een shock te voorkomen;</li>
                <li>om eerste hulp te verlenen bij oog-, oor- en tandletsel;</li>
                <li>om eerste hulp te verlenen bij onderkoeling, bevriezing of oververhitting;</li>
                <li>om eerste hulp te verlenen bij vergiftiging, steken en beten.</li>
            </ul>
            <p>Wanneer krijg ik mijn diploma?</p><p>Na het behalen van uw praktijkexamen krijgt u binnen drie weken het
                diploma opgestuurd door het Oranje Kruis. Dit diploma blijft twee jaar geldig. U dient wel om het geldig
                houden van uw diploma jaarlijks de herhalingslessen te volgen. (8 stuks minimaal en hiervoor betaald u
                een eenmalige bijdrage die u weer terug kunt verdienen door mee te helpen bij evenementen.)</p>

            <Signup />
            <Questions />
        </div>
    </SplitContent>
);
export default CourseEhboBasis;
