import React  from 'react';
import SplitContent from "../../../components/content/SplitContent";
import { Helmet } from "react-helmet";

const Groepen = () => (
    <SplitContent>
        <Helmet>
            <title>Groepslessen: wij geven onze trainingen in een groepvorm | ACR-Opleidingen.nl</title>
            <meta name="description" content="ACR-opleidingen.nl: cursussen in groepen" />
        </Helmet>
        <h4>Groepslessen</h4>
        <p>
            <span>ACR-opleidingen geeft haar cursussen in groepsverband vanaf 4 personen tot maximaal 15 personen afhankelijk van de cursus / training op locatie. Een reanimatie training wordt bijvoorbeeld aan maximaal 8 personen geven zodat er voldoende aandacht aan de cursisten gegeven kan worden.</span>
        </p>

    </SplitContent>
);
export default Groepen;
