import React, { Component } from 'react';
import {Container, Icon, Menu} from "semantic-ui-react";
import GeneralMenu from "./GeneralMenu";
import { Link } from "react-router-dom";
import {withRouter} from 'react-router-dom';

export class HeaderMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItem: '0',
            toggleMenu: (this.props.location.pathname === "/") ? 'active': '',
            preventToggle: (this.props.location.pathname === "/"),
        };

        this.handleItemClick = this.handleItemClick.bind(this);
        this.handleOnMouseOver = this.handleOnMouseOver.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleOnMouseLeave = this.handleOnMouseLeave.bind(this);
        this.setToggle = this.setToggle.bind(this);
    }

    handleItemClick(e, { name }) {
        this.setState({
            activeItem:name,
        });
    }

    componentWillMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                toggleMenu: (this.props.location.pathname === '/') ? 'active' : '',
                preventToggle: (this.props.location.pathname === '/'),
                activeItem: (this.props.location.pathname !== '/groepslessen'
                    && this.props.location.pathname !== '/lotus-specialist'
                    && this.props.location.pathname !== '/brandbestrijding-en-ontruiming'
                ) ? '0' : this.state.activeItem
            });
            this.forceUpdate();
        }
    }

    setToggle(state) {
        if (!this.state.preventToggle) {
            this.setState({toggleMenu: state});
        }
    }

    handleOnMouseOver() {
        this.setToggle('active');
    }

    handleOnMouseLeave() {
        this.setToggle('');
    }

    handleClick(e) {
        if (this.node.contains(e.target)) {
            return;
        }

        this.setToggle('');
    }

    render() {
        const { activeItem, toggleMenu } = this.state;

        return (
            <header className='header-menu'>
                <Container>
                    <Menu inverted>
                        <div className="has-sub-menu" onMouseLeave={this.handleOnMouseLeave} ref={node => this.node = node}>
                            <Menu.Item as='div' style={{'width':'15em'}} name='0' active={activeItem === '0'} onMouseOver={this.handleOnMouseOver} onClick={this.handleItemClick}>ACR Overzicht&nbsp;<Icon name='angle down' /></Menu.Item>
                            <div className={`sub-menu ${toggleMenu}`}>
                                <GeneralMenu />
                            </div>
                        </div>
                        <Menu.Item as={Link} to="/groepslessen" name='1' active={activeItem === '1'} onClick={this.handleItemClick}> Groepslessen</Menu.Item>
                        <Menu.Item as={Link} to="/lotus-specialist" name='2' active={activeItem === '2'} onClick={this.handleItemClick}> LOTUS&reg; specialist</Menu.Item>
                        <Menu.Item as={Link} to="/brandbestrijding-en-ontruiming" name='3' active={activeItem === '3'} onClick={this.handleItemClick}> Brandbestrijding & ontruimingen</Menu.Item>
                    </Menu>
                </Container>
            </header>
        );
    }
}

export default withRouter(props => <HeaderMenu {...props}/>);
