import React, { Component }  from 'react';
import {Image} from "semantic-ui-react";
import PropTypes from 'prop-types';

export default class CourseImage extends Component {
    render() {
        return (
            <Image src={this.props.src} size='large' centered/>
        )
    }
}

CourseImage.propTypes = {
    src: PropTypes.string.isRequired,
};



