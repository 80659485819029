import React  from 'react';
import SplitContent from "../../../components/content/SplitContent";
import { Helmet } from "react-helmet";
import Questions from "../../../components/content/Questions";
import Signup from "../../../components/content/Signup";

const CourseName = "Basic Life Support 'BLS'";
const CoursePageTitle = `Cursus: ${CourseName} | ACR-Opleidingen.nl`;
const CourseMetaDescription = `ACR-opleidingen.nl: ${CourseName}`;

const CourseBls = () => (
    <SplitContent>
        <Helmet>
            <title>{CoursePageTitle}</title>
            <meta name="description" content={CourseMetaDescription} />
        </Helmet>
        <h4>Wat te doen als iemand ( op straat of familie) ineens in elkaar zakt?</h4>
        <div className="twelve wide column"><p>Wat nu ??</p><p>Weet u hoe u moet reanimeren??</p><p>U kunt bij ons een cursus reanimatie
            volgen !!</p><p>Wij leren u in 1 avond wat u moet doen inclusief de bediening van een AED.</p>
            <p>(Automatische externe defibrilator.)</p><p>Hoe meld ik mij aan?</p><p>U kunt mailen naar <a
                href="mailto:info@acr-opleidingen.nl">info@acr-opleidingen.nl</a>. U krijgt dan zo snel mogelijk
                antwoord op uw vragen</p><p>Geef bij uw aanmelding aan of u de cursus in de buurt kunt verzorgd wilt
                hebben op locatie in Wijchen.</p>

            <Signup />
            <Questions />
        </div>
    </SplitContent>
);
export default CourseBls;
