import React  from 'react';
import SplitContent from "../../../components/content/SplitContent";
import { Helmet } from "react-helmet";

const ContentTitle = "Blog post 5";
const CoursePageTitle = `${ContentTitle} | ACR-Opleidingen.nl`;
const CourseMetaDescription = `ACR-opleidingen.nl: ${ContentTitle}`;

const Blog5 = () => (
    <SplitContent>
        <Helmet>
            <title>{CoursePageTitle}</title>
            <meta name="description" content={CourseMetaDescription} />
        </Helmet>
        <h4>{ContentTitle}</h4>
        <p>
            <span>Aanvullende cursus informatie is op dit moment niet beschikbaar</span>
        </p>
    </SplitContent>
);
export default Blog5;
