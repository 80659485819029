import React  from 'react';
import SplitContent from "../../../components/content/SplitContent";
import { Helmet } from "react-helmet";
import Signup from "../../../components/content/Signup";
import Questions from "../../../components/content/Questions";

const CourseName = 'EHBO aan kinderen';
const CoursePageTitle = `Cursus: ${CourseName} | ACR-Opleidingen.nl`;
const CourseMetaDescription = `ACR-opleidingen.nl: ${CourseName}`;

const CourseEhboAanKinderen = () => (
    <SplitContent>
        <Helmet>
            <title>{CoursePageTitle}</title>
            <meta name="description" content={CourseMetaDescription} />
        </Helmet>

        <h4>{CourseName}</h4>
        <div className="twelve wide column"><p>De Eerste hulp aan kinderen cursus is bestemd
            voor iedereen die wil leren om Eerste Hulp Bij Ongelukken te verlenen.</p><p>Deze opleiding is bedoeld voor
            ouders en opa’s en oma’s, maar ook voor personen die beroepsmatig met kinderen werken (bijvoorbeeld scholen
            en kinderdagopvang, gastouders en kraamverzorgenden).</p><p> Inhoud Eerste Hulp aan kinderen cursus De
            opleiding Eerste hulp aan kinderen (EHAK) zal worden verzorgd volgens de richtlijnen en eindtermen van het
            Oranje Kruis.</p><p>Aan de orde komen onder andere:</p>
            <ul>
                <li>Achtergrondinformatie verschillen baby en kind ten opzichte van volwassenen</li>
                <li>Veel voorkomende ongevallen per leeftijdscategorie</li>
                <li>Kleine ongevallen: Wespensteek, tekenbeet, tand er uit, voorwerp in de neus enz.</li>
                <li>Verbandkoffers en gebruik verbandmiddelen</li>
                <li>Diverse verwondingen: Schaafwond, brandwond, snijwond enz.</li>
                <li>Vergiftiging en allergische reacties</li>
                <li>Epilepsie, hersenschudding en koortsstuipen</li>
                <li>Verslikking baby</li>
                <li>Verslikking kind</li>
                <li>Reanimatie baby</li>
                <li>Reanimatie kind</li>
                <li>Bedienen van de AED</li>
                <li>Stabiele zijligging en draaien van buik naar rug</li>
            </ul>
            <p>Lesmateriaal Eerste Hulp aan kinderen cursus</p><p>Ongeveer 3 weken voorafgaand aan de training ontvangen
                de deelnemers het Eerste hulp aan kinderen lesboek.</p><p>Het lesboek dient voorafgaand aan de training
                te worden bestudeerd.</p><p>Eerste Hulp aan kinderen examen en certificering</p><p>Het examen wordt
                afgenomen door de EHBO docent. Het betreft praktijkexamen en gedurende de opleiding worden de
                competenties afgetoetst.</p><p>Wanneer het examen succesvol is afgerond ontvangt de cursist het Oranje
                Kruis diploma eerste hulp aan kinderen.</p>
            <Signup />
            <Questions />
        </div>
    </SplitContent>
);
export default CourseEhboAanKinderen;
