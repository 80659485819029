import React  from 'react';
import SplitContent from "../../../components/content/SplitContent";
import { Helmet } from "react-helmet";

const Ontruiming = () => (
    <SplitContent>
        <Helmet>
            <title>Brandbestrijding en ontruiming | ACR-Opleidingen.nl</title>
            <meta name="description" content="ACR-opleidingen.nl: de opgevraagde pagina is niet beschikbaar" />
        </Helmet>
        <h4>Brandbestrijding en ontruiming</h4>
        <p>
            <span>Elke werkgever beschikt over een Risico-inventarisatie en -evaluatie (RI&E). Hierin is opgenomen welk risico het werk van een werknemer met zich meebrengt.</span>
        </p>
        <p>
            <span>Bedrijfshulpverleners zijn de first responders wanneer de slowwhoops afgaan. Tijdens onze cursus worden de BHV'ers getraind hoe zij moeten omgaan in verschillende scenario's en wordt de RI&E doorgenomen om te zorgen voor een snelle ontruiming in noodsituaties. In de meeste gevallen is dit opgenomen in een plan van aanpak.</span>
        </p>
    </SplitContent>
);
export default Ontruiming;
