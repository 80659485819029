import React, { Component } from 'react';
import { Route, Switch, BrowserRouter as Router} from "react-router-dom";

import 'semantic-ui-css/semantic.min.css'
import '../css/index.scss';

import MainTemplate from "../components/general/MainTemplate";

import Home from './Home';

import LotusSpecialist from "./pages/prominent/LotusSpecialist";
import Groepen from "./pages/prominent/Groepen";
import Ontruiming from "./pages/prominent/Ontruiming";

import AllCourses from "./pages/courses/AllCourses";
import CourseEhboBasis from "./pages/courses/CourseEhboBasis";
import CourseBhvBasis from "./pages/courses/CourseBhvBasis";
import CourseEhboAanKinderen from "./pages/courses/CourseEhboAanKinderen";
import CourseEhbdd from "./pages/courses/CourseEhbdd";
import CourseBls from "./pages/courses/CourseBls";
import CoursePbls from "./pages/courses/CoursePbls";
import CourseWandelLetsel from "./pages/courses/CourseWandelLetsel";
import CourseStopDeBloeding from "./pages/courses/CourseStopDeBloeding";

import OverOns from "./pages/general/OverOns";
import OnzeInstructeurs from "./pages/general/OnzeInstructeurs";
import Vacatures from "./pages/general/Vacatures";
import Partners from "./pages/general/Partners";
import KlantInformatie from "./pages/general/KlantInformatie";
import Contact from './pages/general/Contact';

import NoMatch from "./pages/general/NoMatch";
import OfferteAanvraag from "./pages/general/OfferteAanvraag";
import Terugbelverzoek from "./pages/general/Terugbelverzoek";
import Openingstijden from "./pages/general/Openingstijden";
import MeestgesteldeVragen from "./pages/general/MeestgesteldeVragen";
import CursusVergoeding from "./pages/general/CursusVergoeding";
import Locaties from "./pages/general/Locaties";
import AlleVoordelen from "./pages/general/AlleVoordelen";

import AllNews from "./pages/news/AllNews";
import News1 from "./pages/news/News1";
import News2 from "./pages/news/News2";
import News3 from "./pages/news/News3";
import News4 from "./pages/news/News4";
import News5 from "./pages/news/News5";

import AllBlogs from "./pages/blogs/AllBlogs";
import Blog1 from "./pages/blogs/Blog1";
import Blog2 from "./pages/blogs/Blog2";
import Blog3 from "./pages/blogs/Blog3";
import Blog4 from "./pages/blogs/Blog4";
import Blog5 from "./pages/blogs/Blog5";
import AlgemeneVoorwaarden from "./pages/general/AlgemeneVoorwaarden";
import PrivacyBeleid from "./pages/general/PrivacyBeleid";

export default class AppRoot extends Component {
    render() {
        return (
            <Router>
                <MainTemplate>
                    <div>
                        <Switch>
                            <Route exact path="/" component={Home}/>
                            <Route exact path="/groepslessen" component={Groepen} />
                            <Route exact path="/lotus-specialist" component={LotusSpecialist} />
                            <Route exact path="/brandbestrijding-en-ontruiming" component={Ontruiming} />

                            <Route exact path="/over-ons" component={OverOns}/>
                            <Route exact path="/onze-instructeurs" component={OnzeInstructeurs} />
                            <Route exact path="/vacatures" component={Vacatures} />
                            <Route exact path="/partners" component={Partners} />
                            <Route exact path="/klantinformatie" component={KlantInformatie} />
                            <Route exact path="/contact" component={Contact} />
                            <Route exact path="/algemene-voorwaarden" component={AlgemeneVoorwaarden} />
                            <Route exact path="/privacy-beleid" component={PrivacyBeleid} />

                            <Route exact path="/offerte-aanvraag" component={OfferteAanvraag} />
                            <Route exact path="/terugbelverzoek" component={Terugbelverzoek} />
                            <Route exact path="/openingstijden" component={Openingstijden} />
                            <Route exact path="/meestgestelde-vragen" component={MeestgesteldeVragen} />
                            <Route exact path="/cursus-vergoeding" component={CursusVergoeding} />
                            <Route exact path="/locaties" component={Locaties} />
                            <Route exact path="/alle-voordelen" component={AlleVoordelen} />

                            <Route exact path="/cursus" component={AllCourses} />
                            <Route exact path="/cursus/basis-cursus-ehbo" component={CourseEhboBasis} />
                            <Route exact path="/cursus/basis-cursus-bhv" component={CourseBhvBasis} />
                            <Route exact path="/cursus/ehbo-aan-kinderen" component={CourseEhboAanKinderen} />
                            <Route exact path="/cursus/eerste-hulp-bij-drank-en-drugs" component={CourseEhbdd} />
                            <Route exact path="/cursus/basic-life-support" component={CourseBls} />
                            <Route exact path="/cursus/pediatric-basic-life-support" component={CoursePbls} />
                            <Route exact path="/cursus/wandelletsel" component={CourseWandelLetsel} />
                            <Route exact path="/cursus/stop-de-bloeding" component={CourseStopDeBloeding} />

                            <Route exact path="/nieuws" component={AllNews} />
                            <Route exact path="/nieuws/grote-antiterreuroefening-keukenhof" component={News1} />
                            <Route exact path="/nieuws/bericht2" component={News2} />
                            <Route exact path="/nieuws/bericht3" component={News3} />
                            <Route exact path="/nieuws/bericht4" component={News4} />
                            <Route exact path="/nieuws/bericht5" component={News5} />

                            <Route exact path="/blog" component={AllBlogs} />
                            <Route exact path="/blog/blog1" component={Blog1} />
                            <Route exact path="/blog/blog2" component={Blog2} />
                            <Route exact path="/blog/blog3" component={Blog3} />
                            <Route exact path="/blog/blog4" component={Blog4} />
                            <Route exact path="/blog/blog5" component={Blog5} />

                            <Route component={NoMatch} />
                        </Switch>
                    </div>
                </MainTemplate>
            </Router>
        );
    }
}
