import React, { Component } from 'react';
import {Container, Button} from "semantic-ui-react";
import {withRouter} from 'react-router-dom';

export class Banner extends Component {
    render() {
        if (!(this.props.location.pathname === "/")) {
            return <></>;
        }
        return (
            <div className='banner'>
                <Container>
                    <h1>Weet jij wat je moet doen bij een ongeval?</h1>
                    <div>
                        <q>
                            Je bent onderweg en voor je gebeurd een eenzijdig ongeval. Er is verder niemand in de buurt en de persoon in kwestie is buiten bewustzijn.
                        </q>
                    </div>
                    <Button primary>Volg onze basis cursus EHBO</Button>
                </Container>
            </div>
        );
    }
}

export default withRouter(props => <Banner {...props}/>);
