import React  from 'react';
import { Image } from 'semantic-ui-react';
import ImageLotus from "../../../images/specials/lotus.jpg";
import SplitContent from "../../../components/content/SplitContent";
import {Helmet} from "react-helmet";

const content = (
    <div>
        <p>Als cursist leer je eerste hulp te verlenen aan slachtoffers van een ongeluk. Tijdens onze cursussen en trainingen maken wij gebruik van een LOTUS.</p>
        <p>
            LOTUS staat voor: “Landelijke Opleiding Tot Uitbeelding Van Slachtoffers”.
            Dit is een acteur die gespecialiseerd is in het uitbeelden van een slachtoffer waardoor onze cursisten een realistische ervaring beleven tijdens de opleiding. Onze LOTUS slachtoffer heeft een divers aanbod van een simple botbreuk tot traumatische verwondingen.
        </p>
        <p>
            Mede dankzij het goede acteerwerk weten cursisten aan het eind van de cursus of training welke symptomen belangrijk zijn om op te letten. Zoals regelmatig voorkomende beroertes of een suikerpatiënt met een te hoog of te laag suikerspiegel.
        </p>
    </div>
);

const LotusSpecialist = () => (
    <SplitContent>
        <Helmet>
            <title>LOTUS Specialist, (Landelijke opleiding tot uitbeelding van slachtoffers) | ACR-Opleidingen.nl</title>
            <meta name="description" content="ACR-opleidingen.nl: onze lotus specialist maakt dat de cursisten onder een realistische ervaring leren. " />
        </Helmet>
        <h4>De inzet van HET LOTUS-SLACHTOFFER</h4>
        <div className='lotus-specialist'>
            {content}

            <div className="content-image">
                <Image src={ImageLotus} size="medium" />
                <div className="content-help-text"><q>Onze LOTUS als slachtoffer van een grote brand waarbij medisch personeel getraind wordt voor deze situatie.</q></div>
            </div>

        </div>
    </SplitContent>
);
export default LotusSpecialist;
